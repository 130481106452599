import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import DatePicker from './DatePicker'; // Adjust the path as needed
import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment';
import 'moment/locale/th';

const ClassroomWithDates = ({ classroomId }) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [tempDate, setTempDate] = useState(''); // Temporary date for modal input
  const [dateType, setDateType] = useState(''); // 'start' or 'end'
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const fetchDates = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/classroom_detail/get_classroom_dates`,
        { classroom_id: classroomId },
        { withCredentials: true }
      );
      if (response.data.status === 'success') {
        setStartDate(response.data.start_date || '');
        setEndDate(response.data.end_date || '');
      }
    } catch (error) {
      Swal.fire('ข้อผิดพลาด!', 'ไม่สามารถเชื่อมต่อกับเซิร์ฟเวอร์ได้!', 'warning');
    }
  };

  const handleSaveDate = async () => {
    const target = dateType === 'start' ? 'start_date' : 'end_date';
    //alert(`${startDate} - ${endDate}`)

    // Convert date strings to Date objects for comparison
    const selectedDate = new Date(tempDate);
    const startDateObj = startDate ? new Date(startDate) : null;
    const endDateObj = endDate ? new Date(endDate) : null;

    /*if (dateType === 'start' && !tempDate) {
      Swal.fire('แจ้งเตือน!', 'กรุณาเลือกวันเริ่มต้น!', 'warning');
      return;
    }

    if (dateType === 'end' && !tempDate) {
      Swal.fire('แจ้งเตือน!', 'กรุณาเลือกวันสิ้นสุด!', 'warning');
      return;
    }*/

    if (dateType === 'start' && endDateObj && selectedDate > endDateObj) {
      Swal.fire('แจ้งเตือน!', 'วันเริ่มต้นต้องไม่มากกว่าวันสิ้นสุด!', 'warning');
      return;
    }

    if (dateType === 'end' && startDateObj && selectedDate < startDateObj) {
      Swal.fire('แจ้งเตือน!', 'วันสิ้นสุดต้องไม่น้อยกว่าวันเริ่มต้น!', 'warning');
      return;
    }

    try {
      const data = {
        classroom_id: classroomId,
        start_date: dateType === 'start' ? tempDate : null,
        end_date: dateType === 'end' ? tempDate : null,
        target,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/classroom_detail/update_classroom_date`,
        data,
        { withCredentials: true }
      );

      if (response.data.status === 'success') {
        Swal.fire('สำเร็จ!', 'บันทึกวันที่เรียบร้อย!', 'success');
        if (dateType === 'start') {
          setStartDate(tempDate);
        } else {
          setEndDate(tempDate);
        }
        onClose();
      }
    } catch (error) {
      Swal.fire('ข้อผิดพลาด!', 'เกิดข้อผิดพลาด!', 'warning');
    }
  };

  useEffect(() => {
    if (classroomId !== undefined) {
      fetchDates();
    }
  }, [classroomId]);

  // Format date to Thai locale
  const formatDate = (date) => {
    return moment(date).locale('th').format('LL'); // Example: '15 ตุลาคม 2564'
  };

  return (
    <Box>
      <Flex justify="flex-start" mb={4} alignItems="center">
        <Button
          onClick={() => {
            setDateType('start');
            setTempDate(startDate || ''); // Set tempDate to current startDate
            onOpen();
          }}
          colorScheme="blue"
          size="sm"
          mr={2}
        >
          {startDate ? `วันเริ่มต้น: ${formatDate(startDate)}` : 'กำหนดวันเริ่มต้น'}
        </Button>
        <Button
          onClick={() => {
            setDateType('end');
            setTempDate(endDate || ''); // Set tempDate to current endDate
            onOpen();
          }}
          colorScheme="blue"
          size="sm"
        >
          {endDate ? `วันสิ้นสุด: ${formatDate(endDate)}` : 'กำหนดวันสิ้นสุด'}
        </Button>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {dateType === 'start' ? 'กำหนดวันเริ่มต้น' : 'กำหนดวันสิ้นสุด'}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <DatePicker
              initialDate={tempDate}
              onDateChange={(date) => setTempDate(date)} // Update tempDate
              onClear={tempDate === ''} // Clear internal state when `tempDate` is reset
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="blue"
              onClick={handleSaveDate} // Save the selected date
              mr={3}
            >
              บันทึก
            </Button>
            <Button
              colorScheme="red"
              onClick={() => {
                // Clear only the relevant date
                if (dateType === 'start') {
                  setStartDate('');
                } else if (dateType === 'end') {
                  setEndDate('');
                }
                setTempDate(''); // Clear the temporary date
                toast({
                  title: 'รีเซ็ตค่าสำเร็จ!',
                  description:
                    dateType === 'start'
                      ? 'วันเริ่มต้นถูกล้างแล้ว'
                      : 'วันสิ้นสุดถูกล้างแล้ว',
                  status: 'success',
                  duration: 3000,
                  isClosable: true,
                });
              }}
              mr={3}
            >
              ล้างค่า
            </Button>
            <Button onClick={onClose}>ปิด</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


    </Box>
  );
};

export default ClassroomWithDates;
