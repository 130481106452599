import React, { useState, useEffect } from 'react';
import { Box, Select } from '@chakra-ui/react';
import moment from 'moment';

const DatePicker = ({ initialDate, onDateChange, isDisable = false, onClear }) => {
  const currentYear = new Date().getFullYear();
  const buddhistCurrentYear = currentYear + 543 + 1; // Convert to Buddhist year
  const years = Array.from({ length: 100 }, (_, i) => buddhistCurrentYear - i);
  const months = [
    'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
    'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม',
  ];

  const [selectedDay, setSelectedDay] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [days, setDays] = useState([]);

  useEffect(() => {
    if (initialDate) {
      const [year, month, day] = moment(initialDate).format('YYYY-MM-DD').split('-').map(Number);
      setSelectedYear(year + 543); // Convert to Buddhist year
      setSelectedMonth(month);
      setSelectedDay(day);
    } else {
      setSelectedYear('');
      setSelectedMonth('');
      setSelectedDay('');
    }
  }, [initialDate]);

  useEffect(() => {
    if (selectedYear && selectedMonth) {
      const daysInMonth = new Date(selectedYear - 543, selectedMonth, 0).getDate(); // Convert to Gregorian year
      setDays(Array.from({ length: daysInMonth }, (_, i) => i + 1));
    } else {
      setDays([]);
    }
  }, [selectedYear, selectedMonth]);

  const handleDayChange = (event) => {
    const newDay = parseInt(event.target.value, 10);
    setSelectedDay(newDay);
    if (newDay && selectedMonth && selectedYear) {
      onDateChange(formatDate(newDay, selectedMonth, selectedYear - 543)); // Convert back to Gregorian year
    }
  };

  const handleMonthChange = (event) => {
    const newMonth = parseInt(event.target.value, 10);
    setSelectedMonth(newMonth);
    setSelectedDay(''); // Reset day to ensure valid selections
  };

  const handleYearChange = (event) => {
    const newYear = parseInt(event.target.value, 10);
    setSelectedYear(newYear);
    setSelectedDay(''); // Reset day to ensure valid selections
  };

  const formatDate = (day, month, year) => {
    return `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
  };

  // Handle clear data
  useEffect(() => {
    if (onClear) {
      setSelectedDay('');
      setSelectedMonth('');
      setSelectedYear('');
      onDateChange(''); // Notify parent component
    }
  }, [onClear, onDateChange]);

  return (
    <Box display="flex" justifyContent="space-between" width="300px">
      <Select
        isDisabled={isDisable || !selectedMonth || !selectedYear}
        placeholder="วัน"
        value={selectedDay || ''}
        onChange={handleDayChange}
        borderRadius="8px"
      >
        {days.map((day) => (
          <option key={day} value={day}>
            {day}
          </option>
        ))}
      </Select>
      <Select
        isDisabled={isDisable}
        placeholder="เดือน"
        value={selectedMonth || ''}
        onChange={handleMonthChange}
        borderRadius="8px"
      >
        {months.map((month, index) => (
          <option key={index + 1} value={index + 1}>
            {month}
          </option>
        ))}
      </Select>
      <Select
        isDisabled={isDisable}
        placeholder="ปี"
        value={selectedYear || ''}
        onChange={handleYearChange}
        borderRadius="8px"
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </Select>
    </Box>
  );
};

export default DatePicker;
