import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  Icon,
  Select,
  Spacer,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import Card from 'components/Card/Card';
import CardBody from 'components/Card/CardBody';
import CardHeader from 'components/Card/CardHeader';
import Chart from 'react-apexcharts';
import { FaCalendarAlt, FaFileCsv, FaFileExcel } from 'react-icons/fa';
import moment from 'moment'; // Import moment.js for date manipulation
import axios from 'axios';
import DateRangeModal from './modals/DateRangeModal';
import { exportCSV } from 'util/common';
import DashboardCounts from './widgets/DashboardCounts';

function UsageStat() {
  const textColor = useColorModeValue('gray.700', 'white');
  const bgButtonGroup = useColorModeValue('gray.50', 'navy.700');
  const bgActiveButton = useColorModeValue('#fff', 'navy.800');

  const getLastTenYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year > currentYear - 10; year--) {
      years.push(year.toString());
    }
    return years;
  };

  const yearOptions = getLastTenYears();

  const months = [
    { key: 'all', value: 'ทุกเดือน', code: 'all' },
    { key: 'january', value: 'มกราคม', code: '01' },
    { key: 'february', value: 'กุมภาพันธ์', code: '02' },
    { key: 'march', value: 'มีนาคม', code: '03' },
    { key: 'april', value: 'เมษายน', code: '04' },
    { key: 'may', value: 'พฤษภาคม', code: '05' },
    { key: 'june', value: 'มิถุนายน', code: '06' },
    { key: 'july', value: 'กรกฎาคม', code: '07' },
    { key: 'august', value: 'สิงหาคม', code: '08' },
    { key: 'september', value: 'กันยายน', code: '09' },
    { key: 'october', value: 'ตุลาคม', code: '10' },
    { key: 'november', value: 'พฤศจิกายน', code: '11' },
    { key: 'december', value: 'ธันวาคม', code: '12' },
  ];

  const [selectedMonth1, setSelectedMonth1] = useState('all');
  const [selectedYear1, setSelectedYear1] = useState(new Date().getFullYear());
  const [startDate1, setStartDate1] = useState(moment().startOf('year').format('YYYY-MM-DD'));
  const [endDate1, setEndDate1] = useState(moment().endOf('year').format('YYYY-MM-DD'));
  const [downloading1, setDownloading1] = useState(false)
  const [data1, setData1] = useState([]);
  const [cat1, setCat1] = useState([]);

  const [selectedMonth2, setSelectedMonth2] = useState('all');
  const [selectedYear2, setSelectedYear2] = useState(new Date().getFullYear());
  const [startDate2, setStartDate2] = useState(moment().startOf('year').format('YYYY-MM-DD'));
  const [endDate2, setEndDate2] = useState(moment().endOf('year').format('YYYY-MM-DD'));
  const [downloading2, setDownloading2] = useState(false)
  const [data2, setData2] = useState([]);
  const [cat2, setCat2] = useState([]);

  const [selectedMonth3, setSelectedMonth3] = useState('all');
  const [selectedYear3, setSelectedYear3] = useState(new Date().getFullYear());
  const [startDate3, setStartDate3] = useState(moment().startOf('year').format('YYYY-MM-DD'));
  const [endDate3, setEndDate3] = useState(moment().endOf('year').format('YYYY-MM-DD'));
  const [downloading3, setDownloading3] = useState(false)
  const [data3, setData3] = useState([]);
  const [cat3, setCat3] = useState([])

  const [selectedMonth4, setSelectedMonth4] = useState('all');
  const [selectedYear4, setSelectedYear4] = useState(moment().startOf('month').format('YYYY'));
  const [downloading4, setDownloading4] = useState(false)
  const [startDate4, setStartDate4] = useState(moment().startOf('year').format('YYYY-MM-DD'));
  const [endDate4, setEndDate4] = useState(moment().endOf('year').format('YYYY-MM-DD'));
  const [data4, setData4] = useState([]);
  const [cat4, setCat4] = useState([])

  const [selectedMonth5, setSelectedMonth5] = useState('all');
  const [selectedYear5, setSelectedYear5] = useState(new Date().getFullYear());
  const [startDate5, setStartDate5] = useState(moment().startOf('year').format('YYYY-MM-DD'));
  const [endDate5, setEndDate5] = useState(moment().endOf('year').format('YYYY-MM-DD'));
  const [downloading5, setDownloading5] = useState(false)
  const [data5, setData5] = useState([]);
  const [cat5, setCat5] = useState([]);

  const { isOpen: isDateRangeModalOpen, onOpen: openDateRangeModal, onClose: closeDateRangeModal } = useDisclosure();
  const [downloading, setDownloading] = useState(false)

  //const [data3, setData3] = useState([{name: "จำนวน", data:[0,0,0,0,0,0,0,0,0,5,0,0]}]);
  //const [cat3, setCat3] = useState(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);

  useEffect(() => {
    console.log('---------')
    fetchMediaDownloadStat();
  }, [selectedMonth4, selectedYear4])

  useEffect(() => {
    if (startDate1 && endDate1) {
      const a = moment(endDate1);
      const b = moment(startDate1);
      const dif = a.diff(b, 'days');
      if (dif <= 31) {
        fetchRegisterStats(startDate1, endDate1);
      } else {
        fetchRegisterStatsYearly(startDate1, endDate1);
      }
    }
  }, [startDate1, endDate1]);

  useEffect(() => {
    if (startDate2 && endDate2) {
      const a = moment(endDate2);
      const b = moment(startDate2);
      const dif = a.diff(b, 'days');

      console.log(`dif -> ${dif}`);

      if (dif <= 31) {
        fetchLoginStatsMonthly('user_log_stat', setCat2, setData2, startDate2, endDate2);
      } else {
        fetchLoginStatsYearly('user_login_stat_yearly', setCat2, setData2, startDate2, endDate2);
      }
    }
  }, [startDate2, endDate2]);

  useEffect(() => {
    if (startDate3 && endDate3) {
      const a = moment(endDate3);
      const b = moment(startDate3);
      const dif = a.diff(b, 'days');

      console.log(`dif -> ${dif}`);

      if (dif <= 31) {
        fetchMediaStatsMonthly(startDate3, endDate3);
      } else {
        fetchMediaStatsYearly(startDate3, endDate3);
      }
    }
  }, [startDate3, endDate3]);

  useEffect(() => {
    if (startDate5 && endDate5) {
      const a = moment(endDate5);
      const b = moment(startDate5);
      const dif = a.diff(b, 'days');

      console.log(`dif -> ${dif}`);

      if (dif <= 31) {
        fetchLoginStatsMonthly('user_myipst_log_stat', setCat5, setData5, startDate5, endDate5);
      } else {
        fetchLoginStatsYearly('user_myipst_login_stat_yearly', setCat5, setData5, startDate5, endDate5);
      }
    }
  }, [startDate5, endDate5]);

  const mediaGroupLabels = {
    teacher_guide: 'คู่มือครู',
    lesson_plan: 'แนวการจัดการเรียนรู้',
    presentation: 'ไฟล์นำเสนอ',
    video: 'วีดิทัศน์',
    test_and_exercise: 'แบบทดสอบ/แบบฝึกหัด',
    work_sheet: 'ใบกิจกรรม',
    other: 'อื่นๆ',
    null: "ไม่ระบุ"
  };

  const fetchMediaStatsMonthly = async (start_date, end_date) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/dashboard/media_publish_stat_daily`, { start_date, end_date });
      if (response.data.status === 'success') {
        const res = response.data.data; // The array of arrays returned by the API
        const daysInMonth = res.length; // Number of days in the response
        const seriesMap = {};

        // Initialize the seriesMap for each media group with an array of zeros
        Object.keys(mediaGroupLabels).forEach((key) => {
          seriesMap[key] = Array(daysInMonth).fill(0);
        });

        // Iterate over each day's data
        res.forEach((dayData, index) => {
          dayData.forEach(groupData => {
            const groupKey = groupData.mediaGroup;
            if (seriesMap[groupKey] !== undefined) {
              seriesMap[groupKey][index] = groupData.num; // Assign the count to the corresponding day
            }
          });
        });

        // Convert the seriesMap to an array of series objects for the chart
        const seriesData = Object.keys(seriesMap).map(groupKey => ({
          name: mediaGroupLabels[groupKey], // Use the label from the mapping
          data: seriesMap[groupKey]
        }));

        // Set the data and categories for the chart
        setData3(seriesData);
        setCat3(Array.from({ length: daysInMonth }, (_, i) => `${i + 1}`));
      }
    } catch (error) {
      console.error('Error fetching media stats:', error);
    }
  };

  const fetchMediaStatsYearly = async (start_date, end_date) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/dashboard/media_publish_stat_yearly`, { year: moment(start_date).format('YYYY') });
      if (response.data.status === 'success') {
        const res = response.data.data; // The array of objects returned by the API
        const seriesMap = {};

        // Initialize the seriesMap for each media group with an array of zeros
        Object.keys(mediaGroupLabels).forEach((key) => {
          seriesMap[key] = Array(12).fill(0);
        });

        // Iterate over each month's data
        res.forEach((monthData, index) => {
          Object.keys(monthData).forEach((groupKey) => {
            if (seriesMap[groupKey] !== undefined) {
              seriesMap[groupKey][index] = monthData[groupKey]; // Assign the count to the corresponding month
            }
          });
        });

        // Convert the seriesMap to an array of series objects for the chart
        const seriesData = Object.keys(seriesMap).map(groupKey => ({
          name: mediaGroupLabels[groupKey], // Use the label from the mapping
          data: seriesMap[groupKey]
        }));

        // Set the data and categories for the chart
        setData3(seriesData);
        setCat3(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);
      }
    } catch (error) {
      console.error('Error fetching media stats:', error);
    }
  };

  const fetchLoginStatsMonthly = async (api, setCat, setData, start_date, end_date) => {
    console.log(`start_date -> ${start_date}`);
    console.log(`end_date -> ${end_date}`);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/dashboard/${api}`, { start_date, end_date });
      if (response.data.status === 'success') {
        const res = response.data.data;
        console.log(`v: ${JSON.stringify(res)}`);
        const dataArr = [];
        const catArr = [];

        res.student.map((d, i) => {
          catArr.push(i + 1);
        });

        if(api == "user_log_stat"){
          setData([
            {
              name: 'นักเรียน',
              data: res.student,
            },
            {
              name: 'ครู',
              data: res.teacher,
            }
          ]);
        }
        else{
          setData([
            {
              name: 'นักเรียน',
              data: res.student,
            },
            {
              name: 'ครู',
              data: res.teacher,
            },
            {
              name: 'ผู้ปกครอง',
              data: res.guardian,
            },
            {
              name: 'บุคคลทั่วไป',
              data: res.other,
            },
          ]);
        }

        setCat(catArr);

        console.log(dataArr);
        console.log(catArr);
      }
    } catch (error) {
      console.error('Error fetching login stats:', error);
    }
  };

  const fetchMediaDownloadStat = async () => {
    try {
      const param = selectedMonth4 == "all" ? { year: selectedYear4 } : { month: selectedMonth4, year: selectedYear4 }
      console.log(`param = ${JSON.stringify(param)}`)
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/download/list_es_downloaded_mediaitem`, param);

      if (response.status === 200) {
        console.log(`val ---> ${JSON.stringify(response.data)}`)
        const res = response.data;
        //console.log(`val ---> ${res}`)
        const dataArr = [];
        const catArr = [];
        res.teacher_guide.map((d, i) => {
          catArr.push(i + 1);
        });

        setData4([
          {
            name: 'คู่มือครู',
            data: res.teacher_guide,
          },
          {
            name: 'แนวการจัดการเรียนรู้',
            data: res.lesson_plan,
          },
          {
            name: 'ไฟล์นำเสนอ',
            data: res.presentation,
          },
          {
            name: 'วีดิทัศน์',
            data: res.video,
          },
          {
            name: 'แบบทดสอบ/แบบฝึกหัด',
            data: res.test_and_exercise,
          },
          {
            name: 'ใบกิจกรรม',
            data: res.work_sheet,
          },
          {
            name: 'อื่นๆ',
            data: res.other,
          },
          {
            name: 'ไม่ระบุ',
            data: res.null,
          },
        ]);

        if (selectedMonth4 == "all") {
          setCat4(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);
        }
        else {
          setCat4(catArr);
        }

        console.log(dataArr);
        console.log(catArr);
      }
    } catch (error) {
      console.error('Error fetching login stats:', error);
    }
  };

  const fetchRegisterStats = async (start_date, end_date) => {
    console.log(`start_date -> ${start_date}`);
    console.log(`end_date -> ${end_date}`);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/dashboard/user_register_stat`, { start_date, end_date });
      if (response.data.status === 'success') {
        const res = response.data.data;
        const dataArr = [];
        const catArr = [];
        res.student.map((d, i) => {
          catArr.push(i + 1);
        });

        setData1([
          {
            name: 'นักเรียน',
            data: res.student,
          },
          {
            name: 'ครู',
            data: res.teacher,
          },
          {
            name: 'ผู้ปกครอง',
            data: res.guardian,
          },
          {
            name: 'บุคคลทั่วไป',
            data: res.other,
          },
        ]);

        setCat1(catArr);

        console.log(dataArr);
        console.log(catArr);
      }
    } catch (error) {
      console.error('Error fetching login stats:', error);
    }
  };

  const fetchRegisterStatsYearly = async (start_date, end_date) => {
    const year = moment(start_date).format('YYYY');
    console.log(`year -> ${year}`);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/dashboard/user_register_stat_yearly`, { year });
      if (response.data.status === 'success') {
        const res = response.data.data;

        setData1([
          {
            name: 'นักเรียน',
            data: res.student,
          },
          {
            name: 'ครู',
            data: res.teacher,
          },
          {
            name: 'ผู้ปกครอง',
            data: res.guardian,
          },
          {
            name: 'บุคคลทั่วไป',
            data: res.other,
          },
        ]);

        setCat1(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);
      }
    } catch (error) {
      console.error('Error fetching login stats:', error);
    }
  };

  const fetchLoginStatsYearly = async (api, setCat, setData, start_date, end_date) => {
    const year = moment(start_date).format('YYYY');
    console.log(`year -> ${year}`);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/dashboard/${api}`, { year });
      if (response.data.status === 'success') {
        const res = response.data.data;
        console.log(`v: ${JSON.stringify(res)}`);

        if(api == "user_login_stat_yearly"){
          setData([
            {
              name: 'นักเรียน',
              data: res.student,
            },
            {
              name: 'ครู',
              data: res.teacher,
            }
          ]);
        }
        else{
          setData([
            {
              name: 'นักเรียน',
              data: res.student,
            },
            {
              name: 'ครู',
              data: res.teacher,
            },
            {
              name: 'ผู้ปกครอง',
              data: res.guardian,
            },
            {
              name: 'บุคคลทั่วไป',
              data: res.other,
            },
          ]);
        }

        setCat(['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']);
      }
    } catch (error) {
      console.error('Error fetching login stats:', error);
    }
  };

  const handleMonthChange = (event, setStartDate, setEndDate, setSelectedMonth, selectedYear) => {
    const month = event.target.value;
    setSelectedMonth(month);
    //const year = new Date().getFullYear(); // Get the current year or use selected year from state

    if (month === 'all') {
      // Handle "ทุกเดือน" option
      const startDate = moment(`${selectedYear}-01-01`).format('YYYY-MM-DD');
      const endDate = moment(`${selectedYear}-12-31`).format('YYYY-MM-DD');
      setStartDate(startDate);
      setEndDate(endDate);
    } else {
      // Handle individual month selection
      const startDate = moment(`${selectedYear}-${month}-01`).format('YYYY-MM-DD');
      const endDate = moment(startDate).endOf('month').format('YYYY-MM-DD');
      setStartDate(startDate);
      setEndDate(endDate);
    }
  };

  const handleYearChange = (event, setSelectedYear, setStartDate, setEndDate, selectedMonth) => {
    const year = event.target.value;
    setSelectedYear(year)

    const startDate = selectedMonth === 'all' ? `${year}-01-01` : `${year}-${selectedMonth}-01`;
    const endDate = moment(startDate).endOf(selectedMonth === 'all' ? 'year' : 'month').format('YYYY-MM-DD');
    setStartDate(startDate);
    setEndDate(endDate);

    //alert(`${startDate} ${endDate}`)
  };

  const handleMediaDownloadMonthChange = (event) => {
    const month = event.target.value;
    setSelectedMonth4(month);

    const year = selectedYear4; // Use selected year from state

    if (month === 'all') {
      // Handle "ทุกเดือน" option
      const startDate = moment(`${year}-01-01`).format('YYYY-MM-DD');
      const endDate = moment(`${year}-12-31`).format('YYYY-MM-DD');
      setStartDate4(startDate);
      setEndDate4(endDate);
    } else {
      // Handle individual month selection
      const startDate = moment(`${year}-${month}-01`).format('YYYY-MM-DD');
      const endDate = moment(startDate).endOf('month').format('YYYY-MM-DD');
      setStartDate4(startDate);
      setEndDate4(endDate);
    }
  }

  const handleMediaDownloadYearChange = (event) => {
    const year = event.target.value;
    setSelectedYear4(year);

    const month = selectedMonth4; // Use selected month from state

    if (month === 'all') {
      const startDate = moment(`${year}-01-01`).format('YYYY-MM-DD');
      const endDate = moment(`${year}-12-31`).format('YYYY-MM-DD');
      setStartDate4(startDate);
      setEndDate4(endDate);
    } else {
      const startDate = moment(`${year}-${month}-01`).format('YYYY-MM-DD');
      const endDate = moment(startDate).endOf('month').format('YYYY-MM-DD');
      setStartDate4(startDate);
      setEndDate4(endDate);
    }
  }

  const getOptions = (cat, label) => {
    return {
      chart: {
        type: 'bar',
        stacked: true,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: '13px',
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        categories: cat,
        title: {
          text: label,
          style: {
            fontSize: '14px',
            fontWeight: 600,
          },
        },
      },
      legend: {
        position: 'top',
      },
      fill: {
        opacity: 1,
      },
      colors: ['#FF0000', '#008000', '#0000FF', '#FFFF00', '#FFC0CB', '#800080', '#00FFFF', '#808080'], // Use hex codes for colors
    };
  };



  const handleSelectDates = async ({ startDate, endDate }) => {
    await exportCSV('export_media_item_download', moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"), setDownloading)
    closeDateRangeModal();
    console.log("Selected dates:", startDate, endDate);
  };

  return (
    <Flex direction={"column"}>
      <Text fontWeight={700} fontSize={"20px"} ml={2} pt={{ sm: '135px', lg: '90px' }} mb={"20px"}>สถิติการใช้งาน</Text>
      <Flex
        minH={'450px'}
        direction={{ sm: 'column', md: 'row' }}
      >
        <Card px="0px" pb="0px">
          <CardHeader mb="34px" px="22px">
            <Flex justify={'space-between'}>
              <Text color={textColor} fontSize="lg" fontWeight="bold">
                จำนวนการสมัครสมาชิก
              </Text>
              <Spacer />
              <Flex align={'center'}>
                <Flex bg={bgButtonGroup} borderRadius="10px" p="6px" me="10px">
                  <FormControl mr={2} minW={{ sm: '35%', lg: null }}>
                    <Select
                      maxW={'150px'}
                      variant="main"
                      color="gray.700"
                      fontSize="sm"
                      defaultValue={selectedMonth1}
                      onChange={(event) => handleMonthChange(event, setStartDate1, setEndDate1, setSelectedMonth1, selectedYear1)}
                    >
                      {months.map((month) => (
                        <option key={month.key} value={month.code}>
                          {month.value}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <Select
                      maxW={'150px'}
                      variant="main"
                      color="gray.700"
                      fontSize="xs"
                      defaultValue={new Date().getFullYear()}
                      onChange={(event) => handleYearChange(event, setSelectedYear1, setStartDate1, setEndDate1, selectedMonth1)}
                    >
                      {yearOptions.map((year, index) => (
                        <option key={index} value={year}>
                          ปี {year}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Flex>
                <Button isLoading={downloading1} variant="outline" mr={2} leftIcon={<FaFileCsv />} onClick={() => {
                  exportCSV('export_registration_number', startDate1, endDate1, setDownloading1)
                }}>
                  Export
                </Button>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%">
              {data1.length > 0 && <Chart options={getOptions(cat1, (selectedMonth1=="all"?"Months":"Days"))} series={data1} type="bar" height={350} />}
            </Box>
          </CardBody>
        </Card>
      </Flex>

      <Flex
        gap="24px"
        minH={'450px'}
        pt={'20px'}
        direction={{ sm: 'column', md: 'row' }}
      >
        <Card px="0px" pb="0px">
          <CardHeader px="22px">
            <Flex justify={'space-between'}>
              <Text color={textColor} fontSize="lg" fontWeight="bold">
                จำนวนการเข้าใช้งาน MyIPST
              </Text>
              <Spacer />
              <Flex align={'center'}>
                <Flex bg={bgButtonGroup} borderRadius="10px" p="6px" me="10px">
                  <FormControl mr={2} minW={{ sm: '35%', lg: null }}>
                    <Select
                      maxW={'150px'}
                      variant="main"
                      color="gray.700"
                      fontSize="sm"
                      defaultValue={selectedMonth5}
                      onChange={(event) => handleMonthChange(event, setStartDate5, setEndDate5, setSelectedMonth5, selectedYear5)}
                    >
                      {months.map((month) => (
                        <option key={month.key} value={month.code}>
                          {month.value}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <Select
                      maxW={'150px'}
                      variant="main"
                      color="gray.700"
                      fontSize="xs"
                      defaultValue={new Date().getFullYear()}
                      onChange={(event) => handleYearChange(event, setSelectedYear5, setStartDate5, setEndDate5, selectedMonth5)}
                    >
                      {yearOptions.map((year, index) => (
                        <option key={index} value={year}>
                          ปี {year}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Flex>
                <Button isLoading={downloading5} variant="outline" mr={2} leftIcon={<FaFileCsv />} onClick={() => {
                  exportCSV('export_myipst_login_number', startDate5, endDate5, setDownloading5)
                }}>
                  Export
                </Button>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%">
              {data5.length > 0 && <Chart options={getOptions(cat5, (selectedMonth5=="all"?"Months":"Days"))} series={data5} type="bar" height={350} />}
            </Box>
          </CardBody>
        </Card>
      </Flex>

      <Flex mt="24px" minH={'450px'}>
        <Card px="0px" pb="0px">
          <CardHeader mb="34px" px="22px">
            <Flex justify={'space-between'}>
              <Text color={textColor} fontSize="lg" fontWeight="bold">
                จำนวนสื่อที่เผยแพร่
              </Text>
              <Spacer />
              <Flex align={'center'}>
                <Flex bg={bgButtonGroup} borderRadius="10px" p="6px" me="10px">
                  <FormControl mr={2} minW={{ sm: '35%', lg: null }}>
                    <Select
                      maxW={'150px'}
                      variant="main"
                      color="gray.700"
                      fontSize="sm"
                      defaultValue={selectedMonth3}
                      onChange={(event) => handleMonthChange(event, setStartDate3, setEndDate3, setSelectedMonth3, selectedYear3)}
                    >
                      {months.map((month) => (
                        <option key={month.key} value={month.code}>
                          {month.value}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <Select
                      maxW={'150px'}
                      variant="main"
                      color="gray.700"
                      fontSize="xs"
                      defaultValue={new Date().getFullYear()}
                      onChange={(event) => handleYearChange(event, setSelectedYear3, setStartDate3, setEndDate3, selectedMonth3)}
                    >
                      {yearOptions.map((year, index) => (
                        <option key={index} value={year}>
                          ปี {year}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Flex>
                <Button isLoading={downloading3} variant="outline" mr={2} leftIcon={<FaFileCsv />} onClick={() => {
                  exportCSV('export_published_media', startDate3, endDate3, setDownloading3)
                }}>
                  Export
                </Button>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%">
              {data3.length > 0 && <Chart options={getOptions(cat3, (selectedMonth3=="all"?"Months":"Days"))} series={data3} type="bar" height={350} />}
            </Box>
          </CardBody>
        </Card>
      </Flex>

      <Flex mt="24px" minH={'450px'}>
        <Card px="0px" pb="0px">
          <CardHeader mb="34px" px="22px">
            <Flex justify={'space-between'}>
              <Text color={textColor} fontSize="lg" fontWeight="bold">
                จำนวนสื่อที่ถูกดาวน์โหลด
              </Text>
              <Spacer />
              <Flex align={'center'}>

                <Flex bg={bgButtonGroup} borderRadius="10px" p="6px" me="10px">
                  <FormControl mr={2} minW={{ sm: '35%', lg: null }}>
                    <Select
                      maxW={'150px'}
                      variant="main"
                      color="gray.700"
                      fontSize="sm"
                      defaultValue={selectedMonth4}
                      onChange={(event) => handleMediaDownloadMonthChange(event)}
                    >
                      {months.map((month, index) => (
                        <option key={month.key} value={index == 0 ? "all" : index}>
                          {month.value}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <Select
                      maxW={'150px'}
                      variant="main"
                      color="gray.700"
                      fontSize="xs"
                      defaultValue={new Date().getFullYear()}
                      onChange={(event) => handleMediaDownloadYearChange(event)}
                    >
                      {yearOptions.map((year, index) => (
                        <option key={index} value={year}>
                          ปี {year}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Flex>
                <Button isLoading={downloading4} variant="outline" mr={2} leftIcon={<FaFileCsv />} onClick={() => {
                  exportCSV('export_media_item_download', startDate4, endDate4, setDownloading4)
                }}>
                  Export
                </Button>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%">
              {data4.length > 0 && <Chart
                key={JSON.stringify(cat4)} // Force rerender when categories change
                options={getOptions(cat4, (selectedMonth4 === "all" ? "Months" : "Days"))}
                series={data4}
                type="bar"
                height={350}
              />}
            </Box>
          </CardBody>
        </Card>
      </Flex>


      <Flex direction={{ base: "column", lg: "row" }} mt="24px" minH={'50px'}>
        <Card px="0px" pb="0px" mr={{ base: 0, lg: 1 }}>
          <CardHeader align="center" mb="34px" px="22px">
            <Flex justify="start" align="center">
              <Icon as={FaCalendarAlt} color="blue.500" boxSize={6} mr={2} />
              <Text mt="7px" color={textColor} fontSize="lg" fontWeight="bold">
                นำออก (export) ยอดดาวน์โหลดรายสื่อตามช่วงเวลา
              </Text>
              <Button ml={4} variant="outline" onClick={openDateRangeModal} leftIcon={<FaCalendarAlt />}>
                เลือกวันช่วงเวลา
              </Button>
            </Flex>
          </CardHeader>
        </Card>
      </Flex>

      <Text fontWeight={700} fontSize={"20px"} ml={2} pt={"50px"} mb={"20px"}>MyIPST Classroom Dashboard</Text>

      <Flex
        gap="24px"
        minH={'450px'}
        direction={{ sm: 'column', md: 'row' }}
      >
        <Card px="0px" pb="0px">
          <CardHeader mb="34px" px="22px">
            <Flex justify={'space-between'}>
              <Text color={textColor} fontSize="lg" fontWeight="bold">
                จำนวนการเข้าใช้งาน Classroom
              </Text>
              <Spacer />
              <Flex align={'center'}>
                <Flex bg={bgButtonGroup} borderRadius="10px" p="6px" me="10px">
                  <FormControl mr={2} minW={{ sm: '35%', lg: null }}>
                    <Select
                      maxW={'150px'}
                      variant="main"
                      color="gray.700"
                      fontSize="sm"
                      defaultValue={selectedMonth2}
                      onChange={(event) => handleMonthChange(event, setStartDate2, setEndDate2, setSelectedMonth2, selectedYear2)}
                    >
                      {months.map((month) => (
                        <option key={month.key} value={month.code}>
                          {month.value}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <Select
                      maxW={'150px'}
                      variant="main"
                      color="gray.700"
                      fontSize="xs"
                      defaultValue={new Date().getFullYear()}
                      onChange={(event) => handleYearChange(event, setSelectedYear2, setStartDate2, setEndDate2, selectedMonth2)}
                    >
                      {yearOptions.map((year, index) => (
                        <option key={index} value={year}>
                          ปี {year}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                </Flex>
                <Button isLoading={downloading2} variant="outline" mr={2} leftIcon={<FaFileCsv />} onClick={() => {
                  exportCSV('export_login_number', startDate2, endDate2, setDownloading2)
                }}>
                  Export
                </Button>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%">
              {data2.length > 0 && <Chart options={getOptions(cat2, (selectedMonth2=="all"?"Months":"Days"))} series={data2} type="bar" height={350} />}
            </Box>
          </CardBody>
        </Card>

      </Flex>

      <Flex direction={{ base: "column", lg: "row" }} mt="24px" minH={'50px'}>
        <Card px="0px" pb="0px" mt={{ base: 2, lg: 0 }} ml={{ base: 0, lg: 1 }}>
          <CardHeader mb="34px" px="22px">
            <DashboardCounts />
          </CardHeader>
        </Card>
      </Flex>

      <DateRangeModal
        isOpen={isDateRangeModalOpen}
        onClose={closeDateRangeModal}
        onSelectDates={handleSelectDates}
        isLoading={downloading}
      />
    </Flex>
  );
}

export default UsageStat;
