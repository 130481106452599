
import React from 'react';
import { Tr, Td, Button, Icon, Flex, IconButton } from '@chakra-ui/react';
import { AiOutlineEdit, AiOutlineDelete } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import { FaEye } from 'react-icons/fa';
import { useAuth } from 'auth/AuthProvider';

function TableExamListRow({ no, id, name, email, gradeLevel, subject, isLast, onEdit, onDelete, status }) {

  const history = useHistory();
  const { user, role, isUserExist, login, logout } = useAuth();

  return (
    <Tr>
      <Td>{no}</Td>
      <Td>{id}</Td>
      <Td>{name}</Td>
      {role != "TEACHER" && <Td> {email} </Td>}
      <Td>{gradeLevel}</Td>
      <Td>{subject}</Td>
      {role !== "TEACHER" && <Td>{status==="completed"?"เผยแพร่":"ร่าง"}</Td>}
      <Td>
        <Flex>
          <Button size="sm" mr={1} onClick={onEdit} variant={"outline"}>
            <Icon as={AiOutlineEdit} />
          </Button>
          <Button size="sm" mr={1} colorScheme="red" variant={"outline"} onClick={onDelete}>
            <Icon as={AiOutlineDelete} />
          </Button>
          <Button size="sm" colorScheme="green" variant={"outline"} onClick={()=>{history.push(`question-list?eid=${id}&ename=${name}`)}}>
            <Icon as={FaEye} />
          </Button>
        </Flex>
      </Td>
    </Tr>
  );
}

export default TableExamListRow;
