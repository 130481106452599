import React, { useState, useEffect } from "react";
import Card from "components/Card/Card";
import { CgShapeCircle, CgArrowUp, CgArrowDown, CgTrash } from "react-icons/cg";
import { useToast, Stack, Flex, Text, Box, Icon, IconButton, useColorModeValue, Grid, Menu, MenuButton, MenuList, MenuItem, Button, Tooltip, useDisclosure, RadioGroup, Radio } from "@chakra-ui/react";
import { MdImportExport, MdMenu, MdUpdate } from "react-icons/md";
import { IoEllipsisVerticalSharp, IoPencilSharp, IoSettings, IoSettingsOutline } from 'react-icons/io5';
import QuestionPreviewBox from "./widgets/QuestionPreviewBox";
import { DeleteIcon, ArrowUpIcon, ArrowDownIcon, AddIcon, EditIcon } from "@chakra-ui/icons";
import Swal from 'sweetalert2'
import QuestionPreviewModal from './modals/QuestionPreviewModal';
import QuestionSetImportModal from "./modals/QuestionSetImportModal";
import { BiImport } from "react-icons/bi";
import { FaFileExport, FaFileImport } from "react-icons/fa";
import { useHistory } from 'react-router-dom';
import { showWarningToast } from "util/alertUtil";
import { getParam } from "util/common";
import axios from "axios";
import { API_URL } from "variables/constant";
import { showWarningDialog } from "util/alertUtil";
import { confirmDeleteDialog } from "util/alertUtil";
import { showSuccessToast } from "util/alertUtil";
import Breadcrumbs from "./widgets/Breadcrumbs";
import { showErrorToast } from "util/alertUtil";
import { exportCSV } from "util/common";
import DateRangeModal from "./modals/DateRangeModal";
import moment from "moment";
import { useAuth } from "auth/AuthProvider";

const QuestionList = () => {
    const history = useHistory();
    const id = 'test-toast';
    const toast = useToast();
    const textColor = useColorModeValue("gray.700", "white");
    const [initialIndex, setInitialIndex] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(null);
    const [isUpdating, setIsUpdating] = useState(false); // Spinner state
    const [isExerciseLoading, setExerciseLoading] = useState(false);

    const [isPreviewOpen, setIsPreviewOpen] = useState(false);
    const [isQuestionImportOpen, setIsQuestionImportOpen] = useState(false);

    const [steps, setSteps] = useState([]);
    const [summary, setSummary] = useState({ total: 0, choice: 0, fill: 0, matching: 0 });
    const [questions, setQuestions] = useState([])
    const [importedQuestions, setImportedQuestions] = useState()
    const [deleteQuestionIDAfterImport, setDeleteQuestionIDAfterImport] = useState()

    const [selectedQuestionID, setSelectedQuestionID] = useState()
    const [selectedQuestionType, setSelectedQuestionType] = useState()
    const { isOpen: isDateRangeModalOpen, onOpen: openDateRangeModal, onClose: closeDateRangeModal } = useDisclosure();
    const [downloading, setDownloading] = useState(false)
    const [publicStatus, setPublicStatus] = useState("draft")
    const { role } = useAuth();

    const breadcumLinks = [
        { url: '/#/user/exam-list', text: 'รายการแบบฝึกหัด' },
        { url: undefined, text: 'สร้าง/แก้ไข/นำเข้า แบบฝึกหัด' }
    ];

    useEffect(() => {
        const eid = getParam("eid")
        loadExamStatus(eid)
        loadAllQuestion(eid); // Replace 'exam_id' with the actual exam ID
    }, []);

    useEffect(()=>{
        if(importedQuestions){
            setIsPreviewOpen(true)
        }
    }, [importedQuestions])

    useEffect(()=>{
        if(selectedQuestionID && selectedQuestionType){
            const eid = getParam("eid")
            const ename = getParam("ename")
            history.push(`exam-builder?eid=${eid}&ename=${ename}&qid=${selectedQuestionID}&qtype=${selectedQuestionType}`) 
        }
    },[selectedQuestionID, selectedQuestionType])

    const handleDeleteQuestion = async (question_id, index) => {
        confirmDeleteDialog(
            "ยืนยันลบแบบฝึกหัด",
            `คุณต้องการลบข้อ "${index + 1}" ใช่หรือไม่`,
            async () => {
                try {
                    const response = await axios.post(`${process.env.REACT_APP_API_URL}/questions/delete_question`, { question_id }, { withCredentials: true });
                    if (response.data.status === 'success') {
                        showSuccessToast(toast, "ลบเรียบร้อย", `ลบข้อ ${index + 1} แล้ว`);
    
                        const newSteps = steps.filter((_, i) => i !== index);
                        const newQuestions = questions.filter((_, i) => i !== index);
    
                        setSteps(newSteps);
                        setQuestions(newQuestions);
    
                        // Recalculate the summary
                        const newSummary = newQuestions.reduce(
                            (acc, question) => {
                                if (question.question_type === "choice") acc.choice++;
                                if (question.question_type === "fill") acc.fill++;
                                if (question.question_type === "matching") acc.matching++;
                                acc.total++;
                                return acc;
                            },
                            { total: 0, choice: 0, fill: 0, matching: 0 }
                        );
    
                        setSummary(newSummary);
    
                    } else {
                        showWarningToast(toast, "ลบไม่สำเร็จ", `ไม่สามารถลบข้อ ${index + 1} ได้กรุณาลองใหม่อีกครั้ง`);
                    }
                } catch (error) {
                    showWarningToast(toast, "ลบไม่สำเร็จ", `ไม่สามารถลบข้อ ${index + 1} ได้กรุณาลองใหม่อีกครั้ง`);
                    console.error('Error deleting exam:', error);
                }
            }
        );
    };

    const loadAllQuestion = async (exam_id, cur_question_id = null, num = null) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/exams/list_all_question`, { exam_id });
            const res = response.data;
    
            if (res && res.status === "success") {
                const qs = res.question;
                let matching = 0;
                let fill = 0;
                let choice = 0;
                const total = qs.length;

                setQuestions(qs)
    
                // Map through questions to set initial steps
                let steps = qs.map((e, index) => {
                    if (e.question_type === "choice") choice++;
                    if (e.question_type === "fill") fill++;
                    if (e.question_type === "matching") matching++;
    
                    return {
                        id: e.id,
                        isUpdated: false,
                        question_type: e.question_type,
                        order_num: e.order_num,
                        source: e.source,
                        otim_item_id: e.otim_item_id,
                        create_datetime: e.create_datetime,
                        index: index + 1,
                        total,
                    };
                });
    
                // Set initial steps without update checks
                setSteps(steps);
                setSummary({ total, choice, fill, matching });
    
                // Now, check for updates if there are otim_item_ids
                steps = await checkForUpdates(steps);
    
                // Update steps again with the isUpdated flags set
                console.log(JSON.stringify(steps))
                setSteps(steps);
    
                if (cur_question_id !== null) {
                    //alert(cur_question_id)
                    loadQuestion(cur_question_id, num);
                }
            }
        } catch (error) {
            showWarningToast(toast, "Error!", `${error}`, 'warning');
        }
    };
    
    const checkForUpdates = async (steps) => {
        const updatedSteps = await Promise.all(
            steps.map(async (step) => {
                if (!step.otim_item_id) return step;
    
                try {
                    const otimResponse = await axios.post(`${process.env.REACT_APP_API_URL}/otds/load_question_by_ids`, { question_ids: step.otim_item_id });
                    const otimRes = otimResponse.data.data;
    
                    console.log(`otimRes => ${JSON.stringify(otimRes?.ResponseFixedRandomTestset)}`)
                    
                    if (otimRes.ResponseFixedRandomTestset?.ResponseTestsetGroup_ResponseFixedRandomTestset?.ResponseTestsetGroup?.ResponseItemGroup_ResponseTestsetGroup?.ResponseItemGroup) {
                        const otimQuestion = otimRes.ResponseFixedRandomTestset.ResponseTestsetGroup_ResponseFixedRandomTestset.ResponseTestsetGroup.ResponseItemGroup_ResponseTestsetGroup.ResponseItemGroup;
                        const lastModified = otimQuestion.LastModifiedDate;
                        //alert(`${step.otim_item_id} create_datetime => ${step.create_datetime} lastModified ==> ${lastModified}`)
                        console.log(`lastModified => ${lastModified}`)

                        if (lastModified && new Date(lastModified) > new Date(step.create_datetime)) {
                            step.isUpdated = true;
                        }
                    }
                } catch (error) {
                    console.error("Error loading OTIM question data:", error);
                }
    
                return step;
            })
        );
    
        return updatedSteps;
    };
    
    

    const addFromOTIMTestID = () => {
        Swal.fire({
          title: "นำเข้าคำถามจาก Question ID",
          text: "กรุณาใส่ Question ID ที่ได้จากจากระบบ Online Testing",
          input: "text",
          inputAttributes: {
            autocapitalize: "off"
          },
          showCancelButton: true,
          confirmButtonColor: "#0476D0",
          confirmButtonText: "นำเข้า",
          cancelButtonText: "ยกเลิก",
          showLoaderOnConfirm: true,
          preConfirm: async (inputValue) => {
            if (!inputValue) {
              Swal.showValidationMessage("กรุณาใส่ Question ID");
              return false;
            }
            return inputValue;
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            const questionID = result.value; // Get the input value here
            //console.log(questionID); // Do something with the input value
            openPreview(questionID); // Pass the input value to your openPreview function
          }
        });
      };

    const openPreview = (questionID) => {
        axios.post(`${process.env.REACT_APP_API_URL}/otds/load_question_by_ids`, { question_ids: questionID })
        .then(response => {
            const res = response.data;
            if(res.status == "success"){
                setImportedQuestions(res.data)
                setDeleteQuestionIDAfterImport(undefined)
            }
            else{
                showWarningDialog("แจ้งเตือน", "ไม่สามารถดึงคำถามจาก server ได้")
            }
        })

        console.log(JSON.stringify(questionID))
        //setIsPreviewOpen(true);
    };

    const loadExamStatus = (eid) => {
        setExerciseLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}/exams/load_exam`, {exam_id: eid}, { withCredentials: true })
        .then(function (response) {
            if (response.data && response.data.status === "success") {
                const status = response.data.exam.status;
                if(status){
                    setPublicStatus(status)
                }
            }
        })
        .catch(function (error) {
            showWarningToast(toast, "แจ้งเตือน", "ไม่สามารถโหลดชุดข้อสอบได้");
        })
        .finally(function () {
            setExerciseLoading(false);
        });
    }

    const saveExamStatus = (exam_id, status) => {
        setExerciseLoading(true);
        axios.post(`${process.env.REACT_APP_API_URL}/myipst/set_media_status`, {exam_id, status}, { withCredentials: true })
        .then(function (response) {
            if (response.data && response.data.status !== "success") {
                showWarningToast(toast, "แจ้งเตือน", "ไม่สามารถบันทึกสถานะได้");
            }
        })
        .catch(function (error) {
            showWarningToast(toast, "แจ้งเตือน", "ไม่สามารถบันทึกสถานะได้");
        })
        .finally(function () {
            setExerciseLoading(false);
        });
    }

    const onImportSuccess = () =>{
        const eid = getParam("eid")
        loadAllQuestion(eid);
        setIsPreviewOpen(false);
    }

    const closePreview = () => {
        setIsPreviewOpen(false);
    };

    const openQuestionImport = () => {
        setIsQuestionImportOpen(true);
    };

    const closeQuestionImport = () => {
        setIsQuestionImportOpen(false);
    };

    function swapQuestionOrderByIDs(qid1, qid2, order1, order2) {
        axios.post(`${process.env.REACT_APP_API_URL}/questions/swap_question_order`, {
            qid1,
            qid2,
            order1,
            order2
        })
        .then(response => {
            if (response.data.status == "success") {
                showSuccessToast(toast, "สำเร็จ!", 'สลับตำแหน่งเรียบร้อย!')
            } else {
                showWarningToast(toast, "Error!", 'ไม่สามารถสลับตำแหน่งได้!')
            }
        })
        .catch(error => {
            showErrorToast(toast, "Error!", 'ไม่สามารถสลับตำแหน่งได้')
        });
    }
    
    const moveStepUp = (index) => {
        if (index === 0) return;
    
        const newSteps = [...steps];
        const tempStep = newSteps[index];
        newSteps[index] = newSteps[index - 1];
        newSteps[index - 1] = tempStep;
    
        const newQuestions = [...questions];
        const tempQuestion = newQuestions[index];
        newQuestions[index] = newQuestions[index - 1];
        newQuestions[index - 1] = tempQuestion;
    
        setSteps(newSteps);
        setQuestions(newQuestions);
    
        const old_order = newSteps[index].order_num;
        const old_id = newSteps[index].id;
        const new_order = newSteps[index - 1].order_num;
        const new_id = newSteps[index - 1].id;
    
        swapQuestionOrderByIDs(old_id, new_id, old_order, new_order);
    };
    
    const moveStepDown = (index) => {
        if (index === steps.length - 1) return;
    
        const newSteps = [...steps];
        const tempStep = newSteps[index];
        newSteps[index] = newSteps[index + 1];
        newSteps[index + 1] = tempStep;
    
        const newQuestions = [...questions];
        const tempQuestion = newQuestions[index];
        newQuestions[index] = newQuestions[index + 1];
        newQuestions[index + 1] = tempQuestion;
    
        setSteps(newSteps);
        setQuestions(newQuestions);
    
        const old_order = newSteps[index].order_num;
        const old_id = newSteps[index].id;
        const new_order = newSteps[index + 1].order_num;
        const new_id = newSteps[index + 1].id;
    
        swapQuestionOrderByIDs(old_id, new_id, old_order, new_order);
    };

    const handleSelectIndex = (index) => {
        setSelectedIndex(index);
        setInitialIndex(index);
    };

    const handlePrevNextClick = (direction) => {
        let newIndex;
        if (direction === 'prev') {
            newIndex = selectedIndex - 1;
            if (newIndex < 0) newIndex = steps.length - 1;
        } else {
            newIndex = selectedIndex + 1;
            if (newIndex >= steps.length) newIndex = 0;
        }
        setSelectedIndex(newIndex);
        setInitialIndex(newIndex);
    };

    const editQuestion = (index) => {
        const q = questions[index]
        setSelectedQuestionID(q.id)
        setSelectedQuestionType(q.question_type)
        //alert(question.id)
    }

    const handleSelectDates = async ({ startDate, endDate }) => {
        const eid = getParam("eid")
        await exportCSV('export_test_scores', moment(startDate).format("YYYY-MM-DD"), moment(endDate).format("YYYY-MM-DD"), setDownloading, {exportType: "exam", test_id: eid})
        closeDateRangeModal();
        console.log("Selected dates:", startDate, endDate);
    };

    const confirmUpdateQuestion = async (question_id, index) => {
        // Show confirmation dialog
        Swal.fire({
            title: 'ยืนยันการอัพเดทคำถาม',
            text: `คุณต้องการอัพเดทคำถามข้อที่ ${(index + 1)} ใช่หรือไม่?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            denyButtonColor: '#6CB4EE',
            confirmButtonText: 'ใช่',
            cancelButtonText: 'ยกเลิก'
        }).then((result) => {
            if (result.isConfirmed) {
                // Call handleUpdateQuestion if confirmed
                handleUpdateQuestion(question_id, index);
            }
        });
    };

    const handleUpdateQuestion = async (question_id, index) => {
        setIsUpdating(true); // Show spinner
        const otim_id = steps[index].otim_item_id 

        const reimportResponse = await axios.post(`${process.env.REACT_APP_API_URL}/otds/load_question_by_ids`, { question_ids: otim_id});
        if (reimportResponse.data.status === 'success') {
            const updatedQuestion = reimportResponse.data.data;

            console.log(`updatedQuestion => ${JSON.stringify(updatedQuestion)}`)
            setImportedQuestions(updatedQuestion)
            setDeleteQuestionIDAfterImport(question_id)
        } else {
            showWarningToast(toast, "อัพเดทไม่สำเร็จ", `ไม่สามารถนำเข้าคำถามข้อ ${(index + 1)} ได้`);
        }
    };

    return (
        <Flex direction="column" mt={{ sm: '45px', md: '40px' }} overflowX="hidden">
            <Card mt="24px" alignSelf={{ lg: 'flex-start', '2xl': 'stretch' }}>
            <Breadcrumbs links={breadcumLinks} />
                <Grid
                    mt={2}
                    templateColumns={{
                        sm: "1fr",
                        md: "auto 1.5fr auto",
                        lg: "auto 2fr 1fr",
                    }}
                    gap={{ sm: "24px", lg: "20px" }}
                >
                    <Flex direction="column">
                        <Text
                            color={textColor}
                            fontSize="md"
                            fontWeight="bold"
                            mb={2}
                        >
                            รายการคำถาม
                        </Text>
                        {questions && questions.length > 0 && <Box
                            maxH="500px"
                            overflowY="auto"
                            p={4}
                            border="1px solid"
                            borderColor="gray.200"
                            sx={{
                                '&::-webkit-scrollbar': {
                                width: '8px',
                                height: '8px',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                background: 'gray.500',
                                borderRadius: '8px',
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                background: 'gray.600',
                                cursor: "pointer"
                                },
                                '&::-webkit-scrollbar-track': {
                                background: 'gray.100',
                                },
                            }}
                            >
                            {steps.map((step, index) => (
                                <Flex key={index} alignItems="center" minH="60px" justifyContent="start" onClick={() => handleSelectIndex(index)}>
                                    <Flex direction="column" align="center" h="100%" me="5px">
                                    <Icon
                                        cursor="pointer"
                                        onMouseEnter={() => {
                                            if(step.isUpdated && !toast.isActive(id)){
                                                toast({
                                                    id,
                                                    title: `มีการเปลี่ยนแปลงคำถามข้อที่ ${index + 1}`,
                                                    description: `กรุณาอัพเดทให้เป็นเวอร์ชั่นปัจจุบัน`,
                                                    status: 'info',
                                                    duration: 3000,
                                                    isClosable: true,
                                                    position: "bottom-start"
                                                });
                                            }
                                        }}
                                        as={CgShapeCircle}
                                        mt={2}
                                        color="orange.500"
                                        h={"30px"}
                                        w={"30px"}
                                        zIndex="1"
                                        position="relative"
                                        animation={step.isUpdated ? "blink 1s infinite" : "none"}
                                    />
                                        {index !== steps.length - 1 && <Box w="2px" bg="gray.200" h="100%"></Box>}
                                    </Flex>
                                    <Flex direction="column" justifyContent="flex-start" h="100%">
                                        <Flex mt={1}>
                                            <Flex px={1}>
                                                <Button variant={selectedIndex === index ? "solid" : "outline"} w="180px" colorScheme="blue" onClick={() => { setInitialIndex(index) }}>
                                                    <Text fontSize="xs">
                                                        ข้อที่ {index + 1}
                                                    </Text>
                                                    {step.id && (
                                                        <Flex ml={1} minW="100px">
                                                            <Text
                                                                fontSize="xs"
                                                                fontWeight="normal"
                                                            >
                                                                ({step.otim_item_id? "otim id":"id"}: {step.otim_item_id ? step.otim_item_id : step.id})
                                                            </Text>
                                                        </Flex>
                                                    )}
                                                </Button>
                                            </Flex>
                                            <Box ml={1}>
                                                <Menu>
                                                    <MenuButton alignSelf="flex-start">
                                                        <Icon
                                                            as={IoEllipsisVerticalSharp}
                                                            color="gray.400"
                                                            w="15px"
                                                            h="15px"
                                                        />
                                                    </MenuButton>
                                                    <MenuList>
                                                        <MenuItem
                                                            icon={<DeleteIcon />}
                                                            onClick={() => handleDeleteQuestion(step.id, index)}
                                                        >
                                                            ลบ
                                                        </MenuItem>
                                                        <MenuItem
                                                            icon={<ArrowUpIcon />}
                                                            onClick={() => moveStepUp(index)}
                                                        >
                                                            เลื่อนขึ้น
                                                        </MenuItem>
                                                        <MenuItem
                                                            icon={<ArrowDownIcon />}
                                                            onClick={() => moveStepDown(index)}
                                                        >
                                                            เลื่อนลง
                                                        </MenuItem>
                                                        {step.isUpdated && <MenuItem
                                                            icon={<MdUpdate />}
                                                            //onClick={() => setIsPreviewOpen(true)}
                                                            onClick={() => confirmUpdateQuestion(step.id, index)}
                                                        >
                                                            อัพเดทคำถาม
                                                        </MenuItem>}
                                                        {!step.otim_item_id && <MenuItem
                                                            icon={<EditIcon />}
                                                            onClick={() => editQuestion(index)}
                                                        >
                                                            แก้ไข
                                                        </MenuItem>}
                                                    </MenuList>
                                                </Menu>
                                            </Box>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            ))}
                        </Box>}
                    </Flex>
                    
                    <Flex direction="column" maxW="800px">
                        <Flex justify="end" mb={2}>
                            <Button
                                size="sm"
                                ml={2}
                                variant="ghost"
                                colorScheme="blue"
                                leftIcon={<AddIcon />}
                                onClick={() => { 
                                    const eid = getParam("eid")
                                    const ename = getParam("ename")
                                    history.push(`exam-builder?eid=${eid}&ename=${ename}`) 
                                }}
                            >
                                เพิ่มคำถาม
                            </Button>
                            {role != "TEACHER" && <Button
                                size="sm"
                                ml={2}
                                variant="ghost"
                                colorScheme="blue"
                                leftIcon={<BiImport />}
                                onClick={openQuestionImport}
                            >
                                นำเข้าคำถาม
                            </Button>}
                            {role != "TEACHER" && <Button
                                size="sm"
                                ml={2}
                                variant="ghost"
                                colorScheme="blue"
                                leftIcon={<FaFileImport />}
                                onClick={addFromOTIMTestID}
                            >
                                นำเข้าจาก Question ID
                            </Button>}
                        </Flex>
                        {questions && questions.length > 0 && <Box position="relative">
                            <QuestionPreviewBox questionList={questions} handlePrevNextClick={(e) => { handlePrevNextClick(e) }} initialIndex={initialIndex} />
                            <Text
                                position="absolute"
                                bottom="50%"
                                left="20%"
                                color="gray.300"
                                fontWeight="500"
                                fontSize={{base: "8xl", md: "7xl", sm: "6xl"}}
                                transform="rotate(-30deg)"
                                opacity="0.1"
                            >
                                สสวท.
                            </Text>
                        </Box>}
                        {(!questions || questions.length <= 0) && <Flex>
                            <Flex p={5} rounded={10} borderColor={"blackAlpha.200"} borderWidth={"2px"} mt={2} w={"100%"} justify={"center"}>
                                <Text>ไม่มีรายการ</Text>
                            </Flex>
                        </Flex>}
                    </Flex>
                    <Flex direction={"column"} w="100%" justify={"flex-start"}>
                        <Box mt={{ base: 0, md: 10 }} borderWidth="1px" maxW={{ base: "100%", md: "250px" }} borderColor="gray.400" p={2} borderRadius={10}>
                            <Text
                                color={textColor}
                                fontSize="md"
                                fontWeight="bold"
                            >
                                รหัสชุดคำถาม (Test ID)
                            </Text>
                            <Stack direction="column" spacing="12px">
                                <Flex align="center" justify="space-between">
                                    <Text fontSize="lg" fontWeight="normal">
                                        {getParam("eid")}
                                    </Text>
                                </Flex>
                            </Stack>
                        </Box>
                        <Box mt={{ base: 0, md: 2 }} borderWidth="1px" maxW={{ base: "100%", md: "250px" }} borderColor="gray.400" p={2} borderRadius={10}>
                            <Text
                                color={textColor}
                                fontSize="md"
                                fontWeight="bold"
                            >
                                สรุปประเภทคำถาม
                            </Text>
                            <Stack direction="column" spacing="3px">
                                <Flex mt={2} align="center" justify="space-between">
                                    <Text fontSize="sm" fontWeight="normal">
                                        ตัวเลือก:
                                    </Text>
                                    <Text mr={2} fontSize="sm" color={textColor} fontWeight="bold">
                                        {summary.choice}
                                    </Text>
                                </Flex>
                                <Flex align="center" justify="space-between">
                                    <Text fontSize="sm" fontWeight="normal">
                                        แบบเติมคำ:
                                    </Text>
                                    <Text mr={2} fontSize="sm" color={textColor} fontWeight="bold">
                                        {summary.fill}
                                    </Text>
                                </Flex>
                                <Flex align="center" justify="space-between">
                                    <Text fontSize="sm" fontWeight="normal">
                                        แบบจับคู่:
                                    </Text>
                                    <Text mr={2} fontSize="sm" color={textColor} fontWeight="bold">
                                        {summary.matching}
                                    </Text>
                                </Flex>
                            </Stack>
                        </Box>

                        <Box mt={{ base: 0, md: 2 }} align="center" borderWidth="1px" maxW={{ base: "100%", md: "250px" }} borderColor="gray.400" p={2} borderRadius={10}>
                            <Button leftIcon={<FaFileExport color="blue.600" />} fontWeight={400} variant='ghost' onClick={()=>{
                                openDateRangeModal()
                                //window.open('excel/youtube-history.xlsx')
                            }}>Export คะแนน</Button>
                        </Box>

                        {role != "TEACHER" && <Box mt={{ base: 0, md: 2 }} align="center" borderWidth="1px" maxW={{ base: "100%", md: "250px" }} borderColor="gray.400" p={2} borderRadius={10}>
                            <Text borderColor="gray.100" borderWidth={2} p={2} fontSize={"sm"} bgColor="gray.50">สถานะ:</Text>
                          <Flex borderColor="gray.100" borderWidth={2} p={2} w="100%" align="center" justify="left">
                            <RadioGroup onChange={v=>{
                                saveExamStatus(getParam("eid"), v)
                                setPublicStatus(v)
                                }} value={publicStatus}>
                              <Stack direction='row' justify={"center"} align={"center"}>
                                <Radio value='draft'>ร่าง</Radio>
                                <Radio value='completed'>เผยแพร่</Radio>
                              </Stack>
                            </RadioGroup>
                          </Flex>
                        </Box>}

                    </Flex>
                </Grid>
            </Card>
            {importedQuestions && <QuestionPreviewModal onImportSuccess={onImportSuccess} isOpen={isPreviewOpen} onClose={closePreview} questionData={importedQuestions} deleteQuestionIDAfterImport={deleteQuestionIDAfterImport} />}
            <QuestionSetImportModal isOpen={isQuestionImportOpen} onClose={(isReload)=>{
                closeQuestionImport()
                if(isReload){
                    const eid = getParam("eid")
                    loadAllQuestion(eid);
                }
            }} />
            <DateRangeModal
                isOpen={isDateRangeModalOpen}
                onClose={closeDateRangeModal}
                onSelectDates={handleSelectDates}
                isLoading={downloading}
            />
        </Flex>
    );
}

export default QuestionList;